import '@adyen/adyen-web/dist/adyen.css'
import '../features/adyen/assets/adyen-form-overrides.css'
import '../../public/fonts/index.css'
import 'react-toastify/dist/ReactToastify.css'

import { HydrationBoundary } from '@tanstack/react-query'
import { AppProps } from 'next/app'
import { FC } from 'react'

import { AppAnalytics } from 'src/features/analytics'
import { BaseLayout } from 'src/features/layout/components/BaseLayout'
import { AppProviders } from 'src/providers'
import { StyledToast } from 'src/ui/components/StyledToast'

const App: FC<AppProps> = ({ Component, pageProps }) => {
  return (
    <AppProviders>
      <HydrationBoundary state={pageProps.dehydratedState}>
        <div className='App'>
          <BaseLayout>
            <AppAnalytics />
            <StyledToast />
            <Component {...pageProps} />
          </BaseLayout>
        </div>
      </HydrationBoundary>
    </AppProviders>
  )
}

export default App
