import Script from 'next/script'
import { FC } from 'react'

import { env } from 'src/configs/env'

export const GAHeaderTag: FC = () => {
  const googleAnalyticsId = env.GOOGLE_ANALYTICS_ID
  if (!googleAnalyticsId) return null

  return (
    <>
      <Script
        data-testid='ga-plugin'
        strategy='afterInteractive'
        src={`https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`}
      />
      <Script
        data-testid='ga-script'
        strategy='afterInteractive'
        dangerouslySetInnerHTML={{ __html: createInnerHtml(googleAnalyticsId) }}
      />
    </>
  )
}

const createInnerHtml = (googleAnalyticsId: string) => {
  return `
    window.dataLayer = window.dataLayer || []
    function gtag() {dataLayer.push(arguments)}
    gtag('js', new Date())
    gtag('config', '${googleAnalyticsId}')
`.trim()
}
