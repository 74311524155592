import styled from '@emotion/styled'
import { css, Flex, Logo } from '@queimadiaria/ui'
import { FC } from 'react'

import { env } from 'src/configs/env'
import { useIsCrossSellCheckout } from 'src/features/crossSellCheckout/hooks/useIsCrossSellCheckout'

export const Header: FC = () => {
  const isCrossSell = useIsCrossSellCheckout()

  const redirectHomePage = () => {
    if (isCrossSell) window.location.href = `${env.WEB_BASE_URL}/home`
  }

  return (
    <Flex
      sx={{
        height: '50px',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.08)',
        alignItems: 'center',
        backgroundColor: 'white',
      }}
    >
      <Flex sx={{ justifyContent: 'center' }}>
        <LogoWithStyles type='text' onClick={redirectHomePage} />
      </Flex>
    </Flex>
  )
}

const LogoWithStyles = styled(Logo)(
  css({
    width: ['160px', '160px'],
    marginLeft: ['large', 'xlarge'],
  })
)
