import Script from 'next/script'
import { FC } from 'react'

import { env } from 'src/configs/env'
import { useCheckoutParamsFromUrl } from 'src/features/layout/hooks/useCheckoutParamsFromUrl'

import { hotjarTags } from '../helpers/hotjar'

export const HotJarTag: FC = () => {
  const nodeEnv = env.NODE_ENV

  const { isProduct } = useCheckoutParamsFromUrl()
  const type = isProduct ? 'product' : 'digital'
  const script = hotjarTags[type]

  if (nodeEnv?.includes('dev')) return null

  return (
    <>
      <Script
        data-testid='hotjar'
        strategy='afterInteractive'
        dangerouslySetInnerHTML={{ __html: script }}
      />
    </>
  )
}
