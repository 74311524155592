import wretch from 'wretch'

import { env } from '../configs/env'

export const api = wretch()
  .url(env.BASE_URL ?? '')
  .errorType('json')

export const apiPayments = wretch()
  .url(env.PAYMENTS ?? '')
  .errorType('json')

export const getAsset = wretch()
  .url(env.ASSETS_URL ?? '')
  .errorType('json')

export const apiProductPayments = wretch()
  .url(env.BASE_URL ?? '')
  .errorType('json')

export const apiServelessLeads = wretch()
  .url(`${env.API_SERVELESS_LEADS}`)
  .errorType('json')

export const authenticatedApi = wretch()
  .url(env.BASE_URL ?? '')
  .errorType('json')
  .defer(w => {
    const url = new URL(window.location.href)
    const token = url.searchParams.get('token')

    if (!token) return w
    return w.auth(`Bearer ${token}`)
  })

export const authenticatedUserApi = wretch()
  .url(`${env.BASE_URL}`)
  .errorType('json')
  .defer(w => {
    const url = new URL(window.location.href)
    const token = url.searchParams.get('token')

    if (!token) return w
    return w.auth(`${token}`)
  })

export const changeUserEmailApi = wretch()
  .url(`${env.API_USER_BASE}`)
  .errorType('json')
  .defer(w => {
    const url = new URL(window.location.href)
    const token = url.searchParams.get('token')

    if (!token) return w
    return w.auth(`${token}`)
  })

/**
 * Para evitar round trips externas a network do server para resolver DNS,
 * usamos a url do load balancer diretamente.
 */
export const apiOnLoadBalancer = wretch()
  .url(`http://${env.ELB_BASE_URL ?? ''}`)
  .errorType('json')

export const apiGeolocation = wretch()
  .url(`${env.API_GEOLOCATION}`)
  .errorType('json')
