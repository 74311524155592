import { useRouter } from 'next/router'
import { useMemo } from 'react'

import { QueryParams } from '../../checkout/typings/queryParamsType'

const getOrigin = () => {
  const isServer = typeof window === 'undefined'

  if (isServer) return `https://${process.env.HOSTNAME_URL}`
  return window.location.origin
}
export const useCheckoutParamsFromUrl = () => {
  const router = useRouter()
  const routeParams = router.query

  const url = useMemo(
    () => new URL(router.asPath, getOrigin()),
    [router.asPath]
  )

  const rawQueryParams = useMemo(() => {
    return Object.fromEntries(url.searchParams.entries())
  }, [url])

  const queryParams: QueryParams = {
    ...routeParams,
    ...rawQueryParams,
    focusOnCreditCard: rawQueryParams.focusOnCreditCard === '1',
    nupayDefault: rawQueryParams.nupayDefault === '1',
    methodEmptyDefault: rawQueryParams.methodEmptyDefault === '1',
    isBilletMethodEnabled: rawQueryParams.hideBillet === '0',
    isSalesRecovery: Boolean(rawQueryParams.recoveryId),
    isNoBanners: rawQueryParams.noBanners === '1',
    withEmailConfirmationEnabled: rawQueryParams.emailConfirmation === '1',
    isNewUser: rawQueryParams.newUser
      ? rawQueryParams.newUser === 'true'
      : false,
    recoveryId: rawQueryParams.recoveryId,
    isProduct: url.pathname.includes('/pay/'),
    markBump: !!rawQueryParams.markBump && rawQueryParams.markBump === '1',
  }

  return queryParams
}
