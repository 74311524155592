export const env = {
  BILLET_REDIRECT_URL: process.env.NEXT_PUBLIC_BILLET_REDIRECT_URL,
  BASE_URL: process.env.NEXT_PUBLIC_BASE_URL,
  PAYMENTS: process.env.NEXT_PUBLIC_PAYMENTS,
  ELB_BASE_URL: process.env.ELB_BASE_URL,
  ASSETS_URL: process.env.NEXT_PUBLIC_ASSETS_URL,
  ENCRYPTION_KEY: process.env.NEXT_PUBLIC_ENCRYPTION_KEY,
  ADYEN_ENVIRONMENT: process.env.NEXT_PUBLIC_ADYEN_ENVIRONMENT,
  ADYEN_CLIENT_KEY: process.env.NEXT_PUBLIC_ADYEN_CLIENT_KEY,
  GOOGLE_ANALYTICS_ID: process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID,
  GOOGLE_TAG_MANAGER_ID: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID,
  ZENDESK_CHAT_ID: process.env.NEXT_PUBLIC_ZENDESK_CHAT_ID,
  FALLBACK_PRIVACY_POLICE: process.env.NEXT_PUBLIC_FALLBACK_PRIVACY_POLICE,
  FALLBACK_TERMS_OF_USE: process.env.NEXT_PUBLIC_FALLBACK_TERMS_OF_USE,
  CLIENT_INFO_URL: process.env.NEXT_PUBLIC_CLIENT_INFO_URL,
  WEB_BASE_URL: process.env.NEXT_PUBLIC_WEB_BASE_URL,
  MOBILE_BASE_URL: process.env.NEXT_PUBLIC_MOBILE_BASE_URL,
  SENTRY_ENVIRONMENT: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,
  SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
  JWT_BIT_SECRET: process.env.NEXT_PUBLIC_JWT_BIT_SECRET,
  API_USER_BASE: process.env.NEXT_PUBLIC_API_USER_BASE,
  API_SERVELESS_LEADS: process.env.NEXT_PUBLIC_SERVELESS_LEADS,
  PATH_PAYMENTS_CRED_CARD: process.env.NEXT_PUBLIC_PATH_PAYMENTS_CRED_CARD,
  PATH_PAYMENTS_NU_PAY: process.env.NEXT_PUBLIC_PATH_PAYMENTS_NU_PAY,
  PATH_PAYMENTS_PIX: process.env.NEXT_PUBLIC_PATH_PAYMENTS_PIX,
  PATH_PAUMENTS_PIX_STATUS: process.env.NEXT_PUBLIC_PATH_PAUMENTS_PIX_STATUS,
  PATH_PAYMENTS_PRODUCT_INFO:
    process.env.NEXT_PUBLIC_PATH_PAYMENTS_PRODUCT_INFO,
  PATH_PAYMENTS_COUPON_VALIDATE:
    process.env.NEXT_PUBLIC_PATH_PAYMENTS_COUPON_VALIDATE,
  PATH_PAYMENTS_COUPON_VALIDATE_PRODUCT:
    process.env.NEXT_PUBLIC_PATH_PAYMENTS_COUPON_VALIDATE_PRODUCT,
  PATH_PAYMENTS_ORDER: process.env.NEXT_PUBLIC_PATH_PAYMENTS_ORDER,
  PATH_PAYMENTS_ORDER_STATUS:
    process.env.NEXT_PUBLIC_PATH_PAYMENTS_ORDER_STATUS,
  PATH_PAYMENTS_ORDER_SUMMARY:
    process.env.NEXT_PUBLIC_PATH_PAYMENTS_ORDER_SUMMARY,
  PATH_SUPPORT_PRODUCT: process.env.NEXT_PUBLIC_PATH_SUPPORT_PRODUCT,
  PATH_CONFIG_PRODUCT: process.env.NEXT_PUBLIC_PATH_CONFIG_PRODUCT,
  PATH_CONFIG_CHECKOUT: process.env.NEXT_PUBLIC_PATH_CONFIG_CHECKOUT,
  PATH_SHIPPING: process.env.NEXT_PUBLIC_PATH_SHIPPING,
  RECAPTCHA_SITE_KEY: process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY,
  API_GEOLOCATION: process.env.NEXT_PUBLIC_GEOLOCATION,
  NODE_ENV: process.env.NEXT_PUBLIC_NODE_ENV,
  HIBOT_TOKEN: process.env.NEXT_PUBLIC_HIBOT_TOKEN,
  MIXPANEL_TOKEN: process.env.NEXT_PUBLIC_MIXPANEL_TOKEN,
  PATH_OFFER: process.env.NEXT_PUBLIC_PATH_OFFER,
}
