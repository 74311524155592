import Script from 'next/script'
import { FC } from 'react'

import { env } from 'src/configs/env'

export const GTMHeaderTag: FC = () => {
  const tagManagerId = env.GOOGLE_TAG_MANAGER_ID

  if (!tagManagerId) return null

  return (
    <Script
      data-testid='gtm-script'
      strategy='afterInteractive'
      dangerouslySetInnerHTML={{ __html: createInnerHtml(tagManagerId) }}
    />
  )
}

const createInnerHtml = (tagManagerId: string) => {
  return `
  (function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != "dataLayer" ? "&l=" + l : "";
    j.async = true;
    j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
    f.parentNode.insertBefore(j, f);
  })(window, document, "script", "dataLayer", "${tagManagerId}");
  `
}
