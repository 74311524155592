import { useQuery } from '@tanstack/react-query'

import { usePlanIdFromRoute } from 'src/features/checkout/hooks/usePlanIdFromRoute'

import { checkoutApi } from '../apis/checkoutApi'

export function useIsLegacyCheckout() {
  const planIdOrProductSellGroupId = usePlanIdFromRoute()
  const { data } = useQuery({
    queryKey: ['is-legacy-checkout', planIdOrProductSellGroupId],
    queryFn: () =>
      checkoutApi.fetchIsLegacyPaymentProvider(planIdOrProductSellGroupId),
  })

  return data
}
