import { atom } from 'jotai'

import { BilletOrderProps } from '../typings/barcodeBilletTyping'

export const isCopyBarCode = atom(false)

export const billetOrder = atom({} as BilletOrderProps)

export const BilletPaymentAtoms = {
  isCopyBarCode,
  billetOrder,
}
