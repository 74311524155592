import { atom } from 'jotai'
import ReCAPTCHA from 'react-google-recaptcha'
import { v4 as uuidv4 } from 'uuid'

import { paymentAtoms } from 'src/features/payment/atoms/paymentAtoms'
import { ClientGeolocationDetails } from 'src/features/plan/checkout/typings/clientGeolocationTypings'

import { CustumerDataTypings } from '../typings/custumerDataTypings'
import { PaymentMethods } from '../typings/legacyCheckoutTypings'

const isOpenPopover = atom(false)

const availablePaymentMethodsAtom = atom<PaymentMethods[]>([
  PaymentMethods.LegacyCreditCard,
])

const isMethodSelectionEnabledAtom = atom(get => {
  return get(availablePaymentMethodsAtom).length > 1
})

const isCreditCardInactive = atom(false)

const paymentCheckoutInFallback = atom<boolean>(false)

const isCardThumbnailFlipedAtom = atom(false)
const isPaymentWithInstallmentsEnabledAtom = atom(false)

const isPaymentByBillet = atom(false)

const isPaymentByPix = atom(false)

const isNewCheckout = atom(false)
const isDirectCheckout = atom(false)
const customerData = atom({} as CustumerDataTypings)
const isUpdateLoginEmail = atom(false)
const isUpdateParamEmail = atom(false)
const hasParamEmail = atom(false)

const recaptchaRef = atom({} as React.RefObject<ReCAPTCHA>)

const paymentMethodsAtoms = atom('' as PaymentMethods)

const clientGeolocation = atom({} as ClientGeolocationDetails)

const sessionId = atom(uuidv4() as string)

const isSubmitButtomDisabled = atom(get => {
  const isNewCheckout = get(checkoutAtoms.isNewCheckout)

  if (!isNewCheckout) {
    return (
      get(paymentAtoms.isCreatingOrder) ||
      (get(paymentAtoms.hasErrorCreatingOrder) && !get(isPaymentByBillet)) ||
      get(paymentAtoms.isRedirectingAfterSuccess)
    )
  }

  return (
    (get(paymentAtoms.hasErrorCreatingOrder) && get(isPaymentByBillet)) ||
    get(paymentAtoms.isRedirectingAfterSuccess)
  )
})

function uniq<T>(a: T[]) {
  return Array.from(new Set(a))
}

const addBilletAsAvailablePaymentMethod = atom(null, (get, set) => {
  const newAvailablePaymentMethods = [
    ...get(checkoutAtoms.availablePaymentMethodsAtom),
    PaymentMethods.Billet,
  ]

  if (get(checkoutAtoms.isNewCheckout)) {
    set(checkoutAtoms.isPaymentByBillet, true)
  }

  set(
    checkoutAtoms.availablePaymentMethodsAtom,
    uniq(newAvailablePaymentMethods)
  )
})

const addPixAsAvailablePaymentMethod = atom(null, (get, set) => {
  const newAvailablePaymentMethods = [
    ...get(checkoutAtoms.availablePaymentMethodsAtom),
    PaymentMethods.Pix,
  ]

  set(checkoutAtoms.isPaymentByPix, true)

  set(
    checkoutAtoms.availablePaymentMethodsAtom,
    uniq(newAvailablePaymentMethods)
  )
})

const showMorePaymentMethods = atom(null, (get, set) => {
  const newAvailablePaymentMethods = [
    ...get(checkoutAtoms.availablePaymentMethodsAtom),
    PaymentMethods.Pix,
    PaymentMethods.Billet,
    PaymentMethods.Nupay,
  ]

  set(
    checkoutAtoms.availablePaymentMethodsAtom,
    uniq(newAvailablePaymentMethods)
  )
})

export const checkoutAtoms = {
  customerData,
  availablePaymentMethodsAtom,
  showMorePaymentMethods,
  addBilletAsAvailablePaymentMethod,
  addPixAsAvailablePaymentMethod,
  isMethodSelectionEnabledAtom,
  isCardThumbnailFlipedAtom,
  isPaymentWithInstallmentsEnabledAtom,
  isSubmitButtomDisabled,
  isPaymentByBillet,
  isPaymentByPix,
  isOpenPopover,
  isNewCheckout,
  isDirectCheckout,
  isUpdateLoginEmail,
  isCreditCardInactive,
  recaptchaRef,
  paymentMethodsAtoms,
  clientGeolocation,
  sessionId,
  isUpdateParamEmail,
  hasParamEmail,
  paymentCheckoutInFallback,
}
