import { useAtom } from 'jotai'
import jwt from 'jsonwebtoken'
import { useEffect } from 'react'

import { env } from 'src/configs/env'

import { BilletPaymentAtoms } from '../atoms/BilletPaymentAtoms'
import { BilletOrderProps } from '../typings/barcodeBilletTyping'
import { useTryGeneratedBilletOrder } from './useTryGeneratedBilletOrder'

export function useDecryptJsonWebToken() {
  const tokenBilletOrder = useTryGeneratedBilletOrder()

  const [billetOrder, setBilletOrder] = useAtom(BilletPaymentAtoms.billetOrder)

  useEffect(() => {
    if (tokenBilletOrder && env.JWT_BIT_SECRET) {
      jwt.verify(
        tokenBilletOrder,
        env.JWT_BIT_SECRET,
        async function (err, decoded) {
          if (err) {
            window.location.href = '/'
          }

          if (decoded && typeof decoded !== 'string') {
            const order = factoryOrder(decoded)
            setBilletOrder(order)
          }
        }
      )
    }
  }, [tokenBilletOrder])

  return billetOrder as BilletOrderProps
}

const factoryOrder = (dataDecoded: jwt.JwtPayload) => {
  const order = {
    billetBarCode: dataDecoded.billet.billetBarCode,
    billetUrl: dataDecoded.billet.billetUrl,
    offerName: dataDecoded.billet.offerName,
    pixelBillet: dataDecoded.billet.pixelBillet,
    productName: dataDecoded.billet.productName,
    userBillet: {
      email: dataDecoded.billet.userBillet.email,
      name: dataDecoded.billet.userBillet.name,
      phone: dataDecoded.billet.userBillet.phone,
    },
    value: dataDecoded.billet.value,
  }
  return order
}
