import { useQuery } from '@tanstack/react-query'

import { usePlanIdFromRoute } from 'src/features/checkout/hooks/usePlanIdFromRoute'
import { useCheckoutParamsFromUrl } from 'src/features/layout/hooks/useCheckoutParamsFromUrl'

import { checkoutApi } from '../apis/checkoutApi'
import { useIsLegacyCheckout } from './useIsLegacyCheckout'

export function useAvailableCheckout() {
  const planIdOrProductSellGroupId = usePlanIdFromRoute()
  const isLegacyCheckout = useIsLegacyCheckout()
  const { offerId, recoveryId } = useCheckoutParamsFromUrl()

  return useQuery({
    queryKey: [
      'available-checkout',
      isLegacyCheckout,
      planIdOrProductSellGroupId,
      offerId,
    ],
    queryFn: () => {
      if (!planIdOrProductSellGroupId) return

      return checkoutApi.fetchAvailableCheckout(
        planIdOrProductSellGroupId,
        offerId,
        recoveryId
      )
    },
    enabled: !!planIdOrProductSellGroupId,
  })
}
