import { ThemeProvider } from '@queimadiaria/ui'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Provider } from 'jotai'
import { ReactNode, useState } from 'react'

type TAppProvidersProps = {
  children: ReactNode
}

export function AppProviders({ children }: TAppProvidersProps) {
  const [queryClient] = useState(() => new QueryClient())
  return (
    <ThemeProvider>
      <Provider>
        <QueryClientProvider client={queryClient}>
          {children}
        </QueryClientProvider>
      </Provider>
    </ThemeProvider>
  )
}
