import { ClarityTag } from 'src/features/analytics/components/ClarityTag'
import { FBPixelScriptTag } from 'src/features/analytics/components/FBPixelScriptTag'
import { GAHeaderTag } from 'src/features/analytics/components/GAHeaderTag'
import { GTMHeaderTag } from 'src/features/analytics/components/GTMHeaderTag'
import { HotJarTag } from 'src/features/analytics/components/HotjarTag'
import { MixPanelHeaderTag } from 'src/features/analytics/components/MixPanelTag'

export function AppAnalytics() {
  return (
    <>
      <FBPixelScriptTag />
      <GAHeaderTag />
      <GTMHeaderTag />
      <MixPanelHeaderTag />
      <ClarityTag />
      <HotJarTag />
    </>
  )
}
