import styled from '@emotion/styled'
import { ToastContainer } from 'react-toastify'

export function StyledToast() {
  return (
    <StyledToastContainer
      position='bottom-center'
      autoClose={3000}
      hideProgressBar={true}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover={false}
      closeButton={false}
    />
  )
}

const StyledToastContainer = styled(ToastContainer)`
  z-index: 999999;
  width: 379px;

  .Toastify__toast--error {
    background: #ff002e;
  }
  .Toastify__toast-body {
    font-family: 'Graphik';
    font-weight: 500;
    font-size: 1em;
    color: white;
  }
`
