import { api, apiGeolocation, apiOnLoadBalancer } from 'src/infra/api'
import { EntityId } from 'src/infra/typings/globalTypings'

import { Offer } from '../../offer/typings/offerTypings'
import { Product } from '../../product/typings/productTypings'
import { CheckoutDetails } from '../typings/checkoutDetailsTypings'
import { ClientGeolocationDetails } from '../typings/clientGeolocationTypings'

export interface FetchAvailableCheckoutResponse {
  product: Product
  offer: Offer
  checkout: CheckoutDetails
}

async function fetchAvailableCheckout(
  productSellGroupId: EntityId,
  offerId?: EntityId,
  recoveryId?: string
) {
  const isSSR = typeof window === 'undefined'

  const basePath = isSSR ? ':3019' : '/products'
  const endpoint = `${basePath}/checkout-info/${productSellGroupId}`

  const params = {
    ...(offerId ? { offerId } : {}),
    ...(recoveryId ? { recoveryId } : {}),
  }

  const fetch = isSSR ? apiOnLoadBalancer : api
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const data = await fetch.url(endpoint).query(params).get().json<any>()

  const transformedData: FetchAvailableCheckoutResponse = {
    ...data,
    offer: {
      isAvailableNupay: data.offer?.isAvailableNuPay || false,
      ...(data.offer || {}),
    },
    checkout: {
      ...data.checkout,
      facebookPixels:
        data.checkout.facebookPixels &&
        parseRawPixelValues(data.checkout.facebookPixels),
    },
  }

  return transformedData
}

type FacebookIdsField = {
  ids: string[]
}

/**
 * Bullet proof against bad json coming from backend.
 */
const parseRawPixelValues = (rawValues?: string | FacebookIdsField) => {
  if (!rawValues) return undefined
  try {
    const isValueStringfiedJson = typeof rawValues === 'string'
    const parsed: FacebookIdsField = isValueStringfiedJson
      ? JSON.parse(rawValues as string)
      : rawValues

    return parsed?.ids
  } catch (error) {
    return undefined
  }
}

interface PaymentProviderResponse {
  paymentProvider: 'pagarme' | 'adyen'
  productId: string
}

async function fetchIsLegacyPaymentProvider(
  planIdOrProductSellGroupId?: string
) {
  if (!planIdOrProductSellGroupId) return

  const isSSR = typeof window === 'undefined'

  const basePath = isSSR ? ':3019' : '/products'
  const endpoint = `${basePath}/product-sell-group/payment-provider/${planIdOrProductSellGroupId}`

  const fetch = isSSR ? apiOnLoadBalancer : api

  const checkoutType = await fetch
    .url(endpoint)
    .get()
    .json<PaymentProviderResponse>()

  return checkoutType.paymentProvider === 'pagarme'
}

interface FetchCreditCardBrandsResponse {
  creditCard: { brands: string[] }
}
const fetchCreditCardBrandsByCountryCode = async (countryId: string) => {
  const isSSR = typeof window === 'undefined'

  const basePath = isSSR ? ':3020' : '/payments'
  const endpoint = `${basePath}/payment-methods/${countryId}`
  const fetch = isSSR ? apiOnLoadBalancer : api

  return fetch.url(endpoint).get().json<FetchCreditCardBrandsResponse>()
}

const getClientGeolocation = async () => {
  return apiGeolocation.url('/').get().json<ClientGeolocationDetails>()
}

export const checkoutApi = {
  fetchAvailableCheckout,
  fetchIsLegacyPaymentProvider,
  fetchCreditCardBrandsByCountryCode,
  getClientGeolocation,
}
