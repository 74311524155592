import { atom } from 'jotai'

import { SerializedApiError } from 'src/infra/typings/globalTypings'

import { PixPaymentDetailsProps } from '../typings/useTryToCreateOrderPixTypings'

const isCreatingOrder = atom(false)
const isRedirectingAfterSuccess = atom(false)
const hasErrorCreatingOrder = atom(false)

const creatingOrderErrorDetails = atom<SerializedApiError | undefined>(
  undefined
)

const clearErrors = atom(null, (get, set) => {
  set(creatingOrderErrorDetails, undefined)
  set(hasErrorCreatingOrder, false)
})

const pixPaymentDetails = atom<PixPaymentDetailsProps>(
  {} as PixPaymentDetailsProps
)

export const paymentAtoms = {
  isCreatingOrder,
  isRedirectingAfterSuccess,
  hasErrorCreatingOrder,
  creatingOrderErrorDetails,
  clearErrors,
  pixPaymentDetails,
}
