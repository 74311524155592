import { useAtomValue } from 'jotai'
import { FC } from 'react'
import { Box } from 'theme-ui'

import { checkoutAtoms } from 'src/features/checkout/atoms/checkoutAtoms'
import { useCheckoutParamsFromUrl } from 'src/features/layout/hooks/useCheckoutParamsFromUrl'

import { Header } from './Header'

export const BaseLayout: FC = ({ children }) => {
  const { isNoBanners, isProduct } = useCheckoutParamsFromUrl()
  const isOpenPopover = useAtomValue(checkoutAtoms.isOpenPopover)
  const isNewCheckout = useAtomValue(checkoutAtoms.isNewCheckout)

  return (
    <Box
      sx={{
        width: '100%',
        filter: isOpenPopover ? 'blur(2px)' : null,
      }}
    >
      {!isProduct && (!isNoBanners || isNewCheckout) && <Header />}
      {children}
    </Box>
  )
}
