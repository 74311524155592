import { useRouter } from 'next/router'
import Script from 'next/script'
import { ParsedUrlQuery } from 'querystring'
import { FC, memo } from 'react'

import { useDecryptJsonWebToken } from 'src/features/billet/hooks/useDecryptJsonWebToken'
import { useCheckoutParamsFromUrl } from 'src/features/layout/hooks/useCheckoutParamsFromUrl'
import { useCheckoutDetails } from 'src/features/plan/checkout/hooks/useCheckoutDetails'

export const FBPixelScriptTag: FC = () => {
  const { checkoutDetails } = useCheckoutDetails()
  const { isProduct } = useCheckoutParamsFromUrl()

  const pixels = checkoutDetails?.facebookPixels

  const { pixelBillet } = useDecryptJsonWebToken()

  const { query } = useRouter()

  if (pixelBillet) return <IneerTagBillet pixelIds={pixelBillet} />

  if (!pixels) return null

  return !isProduct ? (
    <InnerTag pixelIds={pixels} queryParams={query} />
  ) : (
    <Script
      data-testid='fbpixel'
      strategy='afterInteractive'
      dangerouslySetInnerHTML={{
        __html: `!function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '340066925567642');
          fbq('track', 'InitiateCheckout');
          fbq('track', 'PageView');`,
      }}
    />
  )
}

// Apenas uma separação pra evitar múltiplas calls do script em caso de rerender
const InnerTag: FC<{
  pixelIds: string[]
  queryParams: ParsedUrlQuery
}> = memo(({ pixelIds, queryParams }) => {
  return (
    <Script
      data-testid='fbpixel'
      strategy='afterInteractive'
      dangerouslySetInnerHTML={{
        __html: createInnerHtml(pixelIds, queryParams),
      }}
    />
  )
})

const createInnerHtml = (pixelIds: string[], queryParams: ParsedUrlQuery) => {
  const email = queryParams.email as string
  const name = queryParams.name as string
  const phone = queryParams.phone as string

  if (email && name && phone) {
    const [firstName, lastName] = name.split(' ')

    const em = email.toLowerCase()
    const fn = firstName.toLowerCase()
    const ln = lastName ? lastName.toLowerCase() : ''
    const ph = phone.replace(/[^\w]/gi, '').toLowerCase()

    return `
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      ${pixelIds
        .map(
          id =>
            `fbq('init', '${id}', {em: '${em}', fn: '${fn}', ln: '${ln}', ph: '${ph}'});`
        )
        .join('')}
      fbq('track', 'PageView');
      fbq('track', 'InitiateCheckout');
    `
  }

  return `
  !function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  ${pixelIds.map(id => `fbq('init', ${id});`).join('')}
  fbq('track', 'PageView');
  fbq('track', 'InitiateCheckout');
`
}

const IneerTagBillet: FC<{
  pixelIds: string
}> = memo(({ pixelIds }) => {
  return (
    <Script
      data-testid='fbpixel'
      strategy='afterInteractive'
      dangerouslySetInnerHTML={{
        __html: createInnerHtmlForBillet(pixelIds),
      }}
    />
  )
})

const createInnerHtmlForBillet = (pixelIds: string) => {
  return `
  !function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  ${`fbq('init', ${pixelIds});`}
  fbq('track', 'PageView');
`
}
