export interface CreditCard {
  creditCardNumber: string
  creditCardName: string
  month: string
  year: string
  cvv: string
}

export enum PaymentMethods {
  LegacyCreditCard = 'legacy_credit_card',
  CreditCard = 'credit_card',
  Billet = 'boleto',
  Pix = 'pix',
  Nupay = 'nupay',
}
